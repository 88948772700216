<template>
	<div>
		<b-modal
			id="modal-deposit-detail"
			cancel-variant="outline-secondary"
			:cancel-title="$t('Close')"
			ok-variant="primary"
			centered
			:title="$t('Deposit transaction detail')"
			size="lg"
			body-class="p-2"
			@hidden="resetModal()"
		>
			<b-overlay
				:show="show"
				variant="transparent"
				no-wrap
			/>
			<label class="d-block h5 mb-1">{{ $t('Member details') }}</label>
			<b-table-simple
				responsive
				bordered
				table-class="table-narrow table-white-space table-highlight"
				class="mb-2"
			>
				<b-tbody>
					<b-tr>
						<b-td class="table-td-header">{{ $t('Username') }}</b-td>
						<b-td>{{ deposit.username }}</b-td>
						<b-td class="table-td-header">{{ $t('Full Name') }}</b-td>
						<b-td>{{ deposit.fullName }}</b-td>
						<b-td class="table-td-header">{{ $t('Currency') }}</b-td>
						<b-td>{{ deposit.currency }}</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
			<label class="d-block h5 mb-1">{{ $t('Deposit detail') }}</label>
			<b-table-simple
				responsive
				bordered
				table-class="table-narrow table-white-space table-highlight"
				class="mb-2"
			>
				<b-tbody>
					<b-tr>
						<b-td class="table-td-header">{{ $t('Transaction id') }}</b-td>
						<b-td>{{ deposit.transactionId }}</b-td>
						<b-td class="table-td-header">{{ $t('Payment type') }}</b-td>
						<b-td>{{ resolveType(deposit.paymentType) }}</b-td>
					</b-tr>
					<!-- <b-tr>
						<b-td class="table-td-header">{{ $t('Attachment') }}</b-td>
						<b-td>
							<b-link
								v-for="(attachment, index) in deposit.attachments"
								:key="index"
								:href="attachment"
								target="_blank"
							>
								<b-img
									:src="attachment"
									fluid
									width="50px"
								/>
							</b-link>
						</b-td>
					</b-tr> -->
					<b-tr>
						<b-td class="table-td-header">{{ $t('Company bank name') }}</b-td>
						<b-td>{{ deposit.company_bank_name }}</b-td>
						<b-td class="table-td-header">{{ $t('Company bank account code') }}</b-td>
						<b-td>{{ deposit.company_bank_account_code }}</b-td>
					</b-tr>
					<b-tr>
						<b-td class="table-td-header">{{ $t('Member account name') }}</b-td>
						<b-td>{{ deposit.member_bank_account_name }}</b-td>
						<b-td class="table-td-header">{{ $t('Member bank account no') }}</b-td>
						<b-td>{{ deposit.member_bank_account_number }}</b-td>
					</b-tr>
					<b-tr>
						<b-td class="table-td-header">{{ $t('Member reference no') }}</b-td>
						<b-td>{{ deposit.member_reference_no }}</b-td>
						<b-td class="table-td-header">{{ $t('Amount') }} Gross</b-td>
						<b-td>{{ deposit.amount ? numberFormat(deposit.amount) : '' }}</b-td>
					</b-tr>
					<b-tr>
						<b-td class="table-td-header">{{ $t('Charge amount') }}</b-td>
						<b-td>{{ deposit.charged_amount ? numberFormat(deposit.charged_amount) : '' }}</b-td>
						<b-td class="table-td-header">{{ $t('Amount')}} Net</b-td>
						<b-td>{{ deposit.net_amount ? numberFormat(deposit.net_amount) : '' }}</b-td>
					</b-tr>
					<b-tr>
						<b-td class="table-td-header">{{ $t('Remark') }}</b-td>
						<b-td colspan="3">{{ deposit.remark }}</b-td>
					</b-tr>
				</b-tbody>
			</b-table-simple>
			<txn-logs
				:transaction-id.sync="transactionId"
			/>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
	BOverlay, BImg, BTable, BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend, BFormTextarea, BBadge, BTableSimple, BTr, BTh, BTd, BTbody,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import { formatDateTime, resolveVBank, numberFormat } from '@core/utils/filter'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import baseUrl from '@/libs/base-url'
import useDepositsList from './useDepositsList'
import TxnLogs from '@/views/payments/txn-logs/TxnLogs.vue'

export default {
	components: {
		TxnLogs,
		BOverlay, BImg, BTable, BLink, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BInputGroup, BInputGroupPrepend, BModal, BFormTextarea, BBadge,
		BInputGroupAppend,
		ValidationProvider,
		ValidationObserver,
		BTableSimple, BTr, BTd, BTh, BTbody,
		vSelect,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
		depositData: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			transactionId: null,
			deposit: {
				username: '',
				fullName: '',
				currency: '',
				transactionId: '',
				paymentType: '',
				attachments: '',
				company_bank_name: '',
				company_bank_account_code: '',
				member_bank_account_name: '',
				member_bank_account_number: '',
				member_reference_no: '',
				amount: '',
				charged_amount: '',
				net_amount: '',
				remark: '',
			},
			show: false,
		}
	},
	watch: {
		depositData: {
			deep: true,
			handler(newVal) {
				if (newVal) {
					this.transactionId = newVal.ref
					this.deposit = {
						username: newVal.loginname,
						fullName: newVal.user.name,
						currency: newVal.currency,
						transactionId: newVal.ref,
						paymentType: newVal.type,
						attachments: this.showAttachments(newVal.local_deposit_attachements),
						company_bank_name: newVal.v_bank ? newVal.v_bank.bank?.bank_name : '',
						company_bank_account_code: newVal.v_bank && newVal.v_bank?.account ? resolveVBank(newVal.v_bank?.account, newVal.v_bank.bank?.code) : '',
						member_bank_account_name: newVal.account_name,
						member_bank_account_number: newVal.account_number,
						member_reference_no: newVal.bank_ref,
						amount: newVal.amount / 1000,
						charged_amount: newVal.fee / 1000,
						net_amount: (newVal.amount - newVal.fee) / 1000,
						remark: newVal.remark,
					}
				}
			}
		}
	},
	/* watch: {
		async auditLogId(newVal) {
			if (newVal != -1) {
				this.show = true
				await this.fetchAuditLogDetail(newVal)
				this.show = false
			}
		},
	}, */
	methods: {
		resetModal() {
			this.$emit('update:depositData', null)
			this.deposit = {}
		},
		// eslint-disable-next-line consistent-return
		showAttachments(images) {
			if (images.length > 0) {
				return images.map(image => {
					return baseUrl + image.url
				})
			}
		},
		viewAttachment(image) {
			this.$emit('view-attachment', image)
		},
	},
	setup() {

		const refHistoryTransactiontable = ref(null)

		const refetchData = () => {
			refHistoryTransactiontable.value.refresh()
		}

		const { resolveType, resolveStatus } = useDepositsList()

		return {
			formatDateTime,
			resolveVBank,
			numberFormat,
			refHistoryTransactiontable,
			refetchData,
			resolveStatus,
			resolveType,
		}
	},
}
</script>
<style lang="scss" scoped>
table.table-narrow td {
	padding: 10px !important;
}
.dark-layout .table-highlight .table-td-header {
    background-color: #343d55 !important;
}
.table-highlight td.table-td-header {
    background-color: #f3f2f7;
}
</style>